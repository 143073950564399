$(document).ready(function() {

    $('.nav-primary > ul > li').on('mouseenter', function(e) {
        e.preventDefault();
        if ($(document).width() > 849)
            $(this).children('div').stop().fadeIn(250);
        // $(this).children('div').stop().animate( { height: ["toggle"] }, {duration: 500} );
    })

    $('.nav-primary > ul > li').on('mouseleave', function(e) {
        e.preventDefault();
        if ($(document).width() > 849)
            $(this).children('div').stop().fadeOut(275);
        // $(this).children('div').stop().animate( { height: ["toggle"] }, {duration: 500} );
    })

    $('.menu-toggle').on('click', function(e) {
        e.preventDefault();
        $menu = $(this);
        $('.nav-outer').stop().slideToggle(250, function(){$menu.toggleClass('active')});
        // $('.nav-outer').slideDown('fast');
    })

    $('.home-header').bxSlider({
        controls: false,
        auto: true,
        pause: 7000,
        onSliderLoad: function() {
            console.log($('.home-header').children('a').length);
            if ($('.home-header').children('a').length<4)
            {
                $('.bx-pager').hide();
            }
        }
    });

    $('.image-gallery').bxSlider({
        pagerCustom: '#bx-pager',
        // adaptiveHeight: true,
        controls: false
    });

    twitterFeed();

    overviewSlider = $('.overview-carousel.bxSlider').bxSlider({
        adaptiveHeight: true,
        pager: false,
        onSliderLoad: function() {
            console.log($('.overview-carousel').children('a').length);
            if ($('.overview-carousel').children('a').length<4)
            {
                $('.bx-pager').hide();
                $('.bx-wrapper').css('margin-bottom', 0);
                $('.overview-carousel > a').css('padding-bottom', 0);
                $('.overview-carousel > div').css('padding-bottom', 0);
            }
        }
    });

    $( '.button-wrapper form' ).attr('target', '_blank');
    $( '<input name="submit" type="submit" value="Purchase" />' ).insertAfter($('.button-wrapper input[type=image]').hide());

    storyHeights();

    prevWindowSize = $(window).width();

    $(window).on('resize', function(e){
        var win = $(document); //this = window
        if (win.width() > 849 && prevWindowSize <=849) {
            $('.nav-outer').show();
        }
        if (win.width() <= 849 && prevWindowSize > 849) {
            $('.nav-outer').hide();
        }
        prevWindowSize = win.width();
        storyHeights();
    });
})

function storyHeights() {
    // $('.story-panel > div > div').each(function() {
    //     $(this).css('min-height', 'auto');
    //     $(this).css('padding-top', 0);
    //     if ($(this).prev('figure').height() > $(this).height() && ($(document).width() > 600)) {
    //         $(this).css('min-height', ($(this).prev('figure').height()));
    //         // $(this).css('padding-top', 40 + 'px');
    //     }
    // })
    $(".story-panel > div > figure > img").one("load", function() {
        // do stuff
        console.log($(this).parent('figure').height());
        console.log($(this).parent('figure').next('div').height());
        if ( $(this).parent('figure').height() >
            $(this).parent('figure').next('div').height() && ($(document).width() > 600) ) {
            console.log('yup');
            $(this).parent('figure').next('div').css('min-height', ($(this).parent('figure').height()));
            $(this).parent('figure').next('div').toggleClass('center-vert');
        }
        else {
            console.log('nope');
        }
    }).each(function() {
        if(this.complete) $(this).load();
    });
}

function twitterFeed() {
    container = $("#twitter-feed");
    if (container.length > 0)
    {
        container.tweet({
            username: "chhange_bcc",
            count: 4,
            loading_text: "Loading tweets...",
            avatar_size: 48,
            //retweets: false,
            //template: "{avatar} <b>Chhange</b> {user} {text} {time}"
            template: "{text}&nbsp;{time}"
        });
    }
}
